import $ from 'jquery';


$('#home_new_products .elementor-product-grid').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        }
    ]
});

$('#b-affaires .elementor-product-grid').slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.1,
                slidesToScroll: 1
            }
        }
    ]
});
$('#selection .elementor-product-grid').slick({
    mobileFirst: true,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 768,
            settings: "unslick"
        }
    ]
});

$('.elementor-product-grid').each(function (){
    let productsMiniature = $(this).find('.product-title');
    let heightProductTitle = 0;
    productsMiniature.each(function(){
        const heightThis = $(this).height();
        if(heightThis > heightProductTitle) {
            heightProductTitle = heightThis;
        }
    });
    if(heightProductTitle > 0) {
        productsMiniature.height(heightProductTitle);
    }
});



function heightMenu() {
    if ($(window).width() > 1200) {
        var $elements = $('.mm_columns_contents_ul');
        var maxHeight = 0;
        $elements.each(function () {
            var height = $(this).height();
            if (height > maxHeight) {
                maxHeight = height;
            }
        });
        console.log(maxHeight);

        if (maxHeight != 0) {
            $elements.height(maxHeight);
            $(".mm_menus_li .mm_columns_ul_tab").height(maxHeight + 102);
        }
    }
    else{
        $(".mm_menus_li .mm_columns_ul_tab").height("auto");
    }
}
heightMenu();

$(".mm_menus_li_tab").hover(heightMenu);

$("#search_mobile").on("click", function(){
    $("#search_widget input").focus().focus();
});